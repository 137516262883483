import { defineStore } from "pinia";
import appAxios from "~/src/network/app/appAxios";
import appEndpoints from "~/src/network/app/appEndpoints";

export const useAppChallengeStore = defineStore({
  id: "app-challenge-store",
  state: () => {
    return {
      available: {
        loading: false,
        data: [],
        error: null,
      },
      process: {
        loading: false,
        data: [],
        error: null,
      },
      completed: {
        loading: false,
        data: [],
        error: null,
      },

      challenge: {
        loading: false,
        data: null,
        error: null,
      },

      challengeProgress: {
        loading: false,
        data: null,
        error: null,
      },

      updateProgress: {
        loading: false,
        data: null,
        error: null,
      },
    };
  },
  actions: {
    async getAvailableChallenges() {
      try {
        this.available.loading = true;
        this.available.data = [];
        this.available.error = null;

        const res = await appAxios.get(appEndpoints.getAvailableChallenges);
        console.log(res);

        this.available.loading = false;

        if (res && res.data && res.data.data) {
          this.available.data = res.data.data;
        }
      } catch (error) {
        this.available.loading = false;
        this.available.error = error;

        console.log(error);
      }
    },

    async getProgressChallenges() {
      try {
        this.process.loading = true;
        this.process.data = [];
        this.process.error = null;

        const res = await appAxios.get(appEndpoints.getProgressChallenges);
        console.log(res);

        this.process.loading = false;

        if (res && res.data && res.data.data) {
          this.process.data = res.data.data;
        }
      } catch (error) {
        this.process.loading = false;
        this.process.error = error;

        console.log(error);
      }
    },

    async getCompletedChallenges() {
      try {
        this.completed.loading = true;
        this.completed.data = [];
        this.completed.error = null;

        const res = await appAxios.get(appEndpoints.getCompletedChallenges);
        console.log(res);

        this.completed.loading = false;

        if (res && res.data && res.data.data) {
          this.completed.data = res.data.data;
        }
      } catch (error) {
        this.completed.loading = false;
        this.completed.error = error;

        console.log(error);
      }
    },

    async getChallengeById(id) {
      try {
        this.challenge.data = null;
        this.challenge.loading = true;
        this.challenge.error = null;

        const res = await appAxios.get(
          `${appEndpoints.getChallengeById}/${id}`
        );
        console.log(res.data.data);

        if (res && res.data && res.data.data) {
          this.challenge.data = res.data.data;
        }

        this.challenge.loading = false;
        this.challenge.error = false;
      } catch (error) {
        console.log(error);
        this.challenge.error = error;
        this.challenge.loading = true;
      }
    },

    async getChallengeProgressById(id) {
      try {
        this.challengeProgress.data = null;
        this.challengeProgress.loading = true;
        this.challengeProgress.error = null;

        const res = await appAxios.get(
          `${appEndpoints.getChallengeProgressById}${id}`
        );
        console.log(res.data.data);

        if (res && res.data && res.data.data) {
          this.challengeProgress.data = res.data.data;
        }

        this.challengeProgress.loading = false;
        this.challengeProgress.error = false;
      } catch (error) {
        console.log(error);
        this.challengeProgress.error = error;
        this.challengeProgress.loading = true;
      }
    },

    async updateChallengeProgress(params) {
      try {
        this.updateProgress.data = null;
        this.updateProgress.loading = true;
        this.updateProgress.error = null;
        const res = await appAxios.put(appEndpoints.updateChallengeProgress, {
          ...params,
        });

        console.log("likeArticleById: ", res);

        this.updateProgress.loading = false;
      } catch (error) {
        this.updateProgress.loading = false;
        this.updateProgress.error = error;
      }
    },
  },
});
