import { defineStore } from "pinia";
import appAxios from "~/src/network/app/appAxios";
import appEndpoints from "~/src/network/app/appEndpoints";

export const useAppAuthStore = defineStore({
  id: "app-auth-store",
  state: () => {
    return {
      user: {},
    };
  },
  actions: {
    async appLogin() {
      try {
        const params = { email: "saul@app-bar.com", password: "Appbar@001" };
        const { data: res } = await appAxios.post(appEndpoints.login, params);
        console.log(res);

        if (res && res.data && res.data.user) {
          this.user = res.data.user;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
