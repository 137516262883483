import { default as articlesPOFvax2P7KMeta } from "/opt/atlassian/pipelines/agent/build/pages/articles.vue?macro=true";
import { default as articlesBookmarkhBY0958oxtMeta } from "/opt/atlassian/pipelines/agent/build/pages/articlesBookmark.vue?macro=true";
import { default as articlesDetailSwaaN8u8jUMeta } from "/opt/atlassian/pipelines/agent/build/pages/articlesDetail.vue?macro=true";
import { default as breathingePROMn00ncMeta } from "/opt/atlassian/pipelines/agent/build/pages/breathing.vue?macro=true";
import { default as challengeyTP1CmJeceMeta } from "/opt/atlassian/pipelines/agent/build/pages/challenge.vue?macro=true";
import { default as challengeDetailZ0ZAjP7rjaMeta } from "/opt/atlassian/pipelines/agent/build/pages/challengeDetail.vue?macro=true";
import { default as entryrolOm4QEELMeta } from "/opt/atlassian/pipelines/agent/build/pages/entry.vue?macro=true";
import { default as homeET3tNkLmWfMeta } from "/opt/atlassian/pipelines/agent/build/pages/home.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as postMsgTestnq3XS2MOnzMeta } from "/opt/atlassian/pipelines/agent/build/pages/postMsgTest.vue?macro=true";
import { default as stepCountChangeDeviceOizTgMgwRJMeta } from "/opt/atlassian/pipelines/agent/build/pages/stepCountChangeDevice.vue?macro=true";
import { default as stepCountConnectIntrob7wOf5KrL8Meta } from "/opt/atlassian/pipelines/agent/build/pages/stepCountConnectIntro.vue?macro=true";
import { default as stepCountConnectListxD7SA0PYAbMeta } from "/opt/atlassian/pipelines/agent/build/pages/stepCountConnectList.vue?macro=true";
import { default as stepCountConnectedGCGcDjQytlMeta } from "/opt/atlassian/pipelines/agent/build/pages/stepCountConnected.vue?macro=true";
import { default as stepCountDisconnected45ihMolgNkMeta } from "/opt/atlassian/pipelines/agent/build/pages/stepCountDisconnected.vue?macro=true";
import { default as stepCountIntromJiswzOFLYMeta } from "/opt/atlassian/pipelines/agent/build/pages/stepCountIntro.vue?macro=true";
import { default as stretchC0FH8RSllRMeta } from "/opt/atlassian/pipelines/agent/build/pages/stretch.vue?macro=true";
import { default as stretchTesty1NqGkd3x0Meta } from "/opt/atlassian/pipelines/agent/build/pages/stretchTest.vue?macro=true";
import { default as vitalResult3rkaMOsht8Meta } from "/opt/atlassian/pipelines/agent/build/pages/vitalResult.vue?macro=true";
import { default as vitalResultDetailnilm0zGab8Meta } from "/opt/atlassian/pipelines/agent/build/pages/vitalResultDetail.vue?macro=true";
import { default as vitalScan7wJ7imld4kMeta } from "/opt/atlassian/pipelines/agent/build/pages/vitalScan.vue?macro=true";
import { default as vitalScanDisDA02hQnFIzMeta } from "/opt/atlassian/pipelines/agent/build/pages/vitalScanDis.vue?macro=true";
export default [
  {
    name: articlesPOFvax2P7KMeta?.name ?? "articles___en",
    path: articlesPOFvax2P7KMeta?.path ?? "/articles",
    meta: articlesPOFvax2P7KMeta || {},
    alias: articlesPOFvax2P7KMeta?.alias || [],
    redirect: articlesPOFvax2P7KMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesPOFvax2P7KMeta?.name ?? "articles___tc",
    path: articlesPOFvax2P7KMeta?.path ?? "/tc/articles",
    meta: articlesPOFvax2P7KMeta || {},
    alias: articlesPOFvax2P7KMeta?.alias || [],
    redirect: articlesPOFvax2P7KMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesBookmarkhBY0958oxtMeta?.name ?? "articlesBookmark___en",
    path: articlesBookmarkhBY0958oxtMeta?.path ?? "/articlesBookmark",
    meta: articlesBookmarkhBY0958oxtMeta || {},
    alias: articlesBookmarkhBY0958oxtMeta?.alias || [],
    redirect: articlesBookmarkhBY0958oxtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/articlesBookmark.vue").then(m => m.default || m)
  },
  {
    name: articlesBookmarkhBY0958oxtMeta?.name ?? "articlesBookmark___tc",
    path: articlesBookmarkhBY0958oxtMeta?.path ?? "/tc/articlesBookmark",
    meta: articlesBookmarkhBY0958oxtMeta || {},
    alias: articlesBookmarkhBY0958oxtMeta?.alias || [],
    redirect: articlesBookmarkhBY0958oxtMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/articlesBookmark.vue").then(m => m.default || m)
  },
  {
    name: articlesDetailSwaaN8u8jUMeta?.name ?? "articlesDetail___en",
    path: articlesDetailSwaaN8u8jUMeta?.path ?? "/articlesDetail",
    meta: articlesDetailSwaaN8u8jUMeta || {},
    alias: articlesDetailSwaaN8u8jUMeta?.alias || [],
    redirect: articlesDetailSwaaN8u8jUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/articlesDetail.vue").then(m => m.default || m)
  },
  {
    name: articlesDetailSwaaN8u8jUMeta?.name ?? "articlesDetail___tc",
    path: articlesDetailSwaaN8u8jUMeta?.path ?? "/tc/articlesDetail",
    meta: articlesDetailSwaaN8u8jUMeta || {},
    alias: articlesDetailSwaaN8u8jUMeta?.alias || [],
    redirect: articlesDetailSwaaN8u8jUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/articlesDetail.vue").then(m => m.default || m)
  },
  {
    name: breathingePROMn00ncMeta?.name ?? "breathing___en",
    path: breathingePROMn00ncMeta?.path ?? "/breathing",
    meta: breathingePROMn00ncMeta || {},
    alias: breathingePROMn00ncMeta?.alias || [],
    redirect: breathingePROMn00ncMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/breathing.vue").then(m => m.default || m)
  },
  {
    name: breathingePROMn00ncMeta?.name ?? "breathing___tc",
    path: breathingePROMn00ncMeta?.path ?? "/tc/breathing",
    meta: breathingePROMn00ncMeta || {},
    alias: breathingePROMn00ncMeta?.alias || [],
    redirect: breathingePROMn00ncMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/breathing.vue").then(m => m.default || m)
  },
  {
    name: challengeyTP1CmJeceMeta?.name ?? "challenge___en",
    path: challengeyTP1CmJeceMeta?.path ?? "/challenge",
    meta: challengeyTP1CmJeceMeta || {},
    alias: challengeyTP1CmJeceMeta?.alias || [],
    redirect: challengeyTP1CmJeceMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/challenge.vue").then(m => m.default || m)
  },
  {
    name: challengeyTP1CmJeceMeta?.name ?? "challenge___tc",
    path: challengeyTP1CmJeceMeta?.path ?? "/tc/challenge",
    meta: challengeyTP1CmJeceMeta || {},
    alias: challengeyTP1CmJeceMeta?.alias || [],
    redirect: challengeyTP1CmJeceMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/challenge.vue").then(m => m.default || m)
  },
  {
    name: challengeDetailZ0ZAjP7rjaMeta?.name ?? "challengeDetail___en",
    path: challengeDetailZ0ZAjP7rjaMeta?.path ?? "/challengeDetail",
    meta: challengeDetailZ0ZAjP7rjaMeta || {},
    alias: challengeDetailZ0ZAjP7rjaMeta?.alias || [],
    redirect: challengeDetailZ0ZAjP7rjaMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/challengeDetail.vue").then(m => m.default || m)
  },
  {
    name: challengeDetailZ0ZAjP7rjaMeta?.name ?? "challengeDetail___tc",
    path: challengeDetailZ0ZAjP7rjaMeta?.path ?? "/tc/challengeDetail",
    meta: challengeDetailZ0ZAjP7rjaMeta || {},
    alias: challengeDetailZ0ZAjP7rjaMeta?.alias || [],
    redirect: challengeDetailZ0ZAjP7rjaMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/challengeDetail.vue").then(m => m.default || m)
  },
  {
    name: entryrolOm4QEELMeta?.name ?? "entry___en",
    path: entryrolOm4QEELMeta?.path ?? "/entry",
    meta: entryrolOm4QEELMeta || {},
    alias: entryrolOm4QEELMeta?.alias || [],
    redirect: entryrolOm4QEELMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/entry.vue").then(m => m.default || m)
  },
  {
    name: entryrolOm4QEELMeta?.name ?? "entry___tc",
    path: entryrolOm4QEELMeta?.path ?? "/tc/entry",
    meta: entryrolOm4QEELMeta || {},
    alias: entryrolOm4QEELMeta?.alias || [],
    redirect: entryrolOm4QEELMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/entry.vue").then(m => m.default || m)
  },
  {
    name: homeET3tNkLmWfMeta?.name ?? "home___en",
    path: homeET3tNkLmWfMeta?.path ?? "/home",
    meta: homeET3tNkLmWfMeta || {},
    alias: homeET3tNkLmWfMeta?.alias || [],
    redirect: homeET3tNkLmWfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/home.vue").then(m => m.default || m)
  },
  {
    name: homeET3tNkLmWfMeta?.name ?? "home___tc",
    path: homeET3tNkLmWfMeta?.path ?? "/tc/home",
    meta: homeET3tNkLmWfMeta || {},
    alias: homeET3tNkLmWfMeta?.alias || [],
    redirect: homeET3tNkLmWfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___en",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___tc",
    path: indexOD9t3F2bSJMeta?.path ?? "/tc",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: postMsgTestnq3XS2MOnzMeta?.name ?? "postMsgTest___en",
    path: postMsgTestnq3XS2MOnzMeta?.path ?? "/postMsgTest",
    meta: postMsgTestnq3XS2MOnzMeta || {},
    alias: postMsgTestnq3XS2MOnzMeta?.alias || [],
    redirect: postMsgTestnq3XS2MOnzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/postMsgTest.vue").then(m => m.default || m)
  },
  {
    name: postMsgTestnq3XS2MOnzMeta?.name ?? "postMsgTest___tc",
    path: postMsgTestnq3XS2MOnzMeta?.path ?? "/tc/postMsgTest",
    meta: postMsgTestnq3XS2MOnzMeta || {},
    alias: postMsgTestnq3XS2MOnzMeta?.alias || [],
    redirect: postMsgTestnq3XS2MOnzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/postMsgTest.vue").then(m => m.default || m)
  },
  {
    name: stepCountChangeDeviceOizTgMgwRJMeta?.name ?? "stepCountChangeDevice___en",
    path: stepCountChangeDeviceOizTgMgwRJMeta?.path ?? "/stepCountChangeDevice",
    meta: stepCountChangeDeviceOizTgMgwRJMeta || {},
    alias: stepCountChangeDeviceOizTgMgwRJMeta?.alias || [],
    redirect: stepCountChangeDeviceOizTgMgwRJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountChangeDevice.vue").then(m => m.default || m)
  },
  {
    name: stepCountChangeDeviceOizTgMgwRJMeta?.name ?? "stepCountChangeDevice___tc",
    path: stepCountChangeDeviceOizTgMgwRJMeta?.path ?? "/tc/stepCountChangeDevice",
    meta: stepCountChangeDeviceOizTgMgwRJMeta || {},
    alias: stepCountChangeDeviceOizTgMgwRJMeta?.alias || [],
    redirect: stepCountChangeDeviceOizTgMgwRJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountChangeDevice.vue").then(m => m.default || m)
  },
  {
    name: stepCountConnectIntrob7wOf5KrL8Meta?.name ?? "stepCountConnectIntro___en",
    path: stepCountConnectIntrob7wOf5KrL8Meta?.path ?? "/stepCountConnectIntro",
    meta: stepCountConnectIntrob7wOf5KrL8Meta || {},
    alias: stepCountConnectIntrob7wOf5KrL8Meta?.alias || [],
    redirect: stepCountConnectIntrob7wOf5KrL8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountConnectIntro.vue").then(m => m.default || m)
  },
  {
    name: stepCountConnectIntrob7wOf5KrL8Meta?.name ?? "stepCountConnectIntro___tc",
    path: stepCountConnectIntrob7wOf5KrL8Meta?.path ?? "/tc/stepCountConnectIntro",
    meta: stepCountConnectIntrob7wOf5KrL8Meta || {},
    alias: stepCountConnectIntrob7wOf5KrL8Meta?.alias || [],
    redirect: stepCountConnectIntrob7wOf5KrL8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountConnectIntro.vue").then(m => m.default || m)
  },
  {
    name: stepCountConnectListxD7SA0PYAbMeta?.name ?? "stepCountConnectList___en",
    path: stepCountConnectListxD7SA0PYAbMeta?.path ?? "/stepCountConnectList",
    meta: stepCountConnectListxD7SA0PYAbMeta || {},
    alias: stepCountConnectListxD7SA0PYAbMeta?.alias || [],
    redirect: stepCountConnectListxD7SA0PYAbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountConnectList.vue").then(m => m.default || m)
  },
  {
    name: stepCountConnectListxD7SA0PYAbMeta?.name ?? "stepCountConnectList___tc",
    path: stepCountConnectListxD7SA0PYAbMeta?.path ?? "/tc/stepCountConnectList",
    meta: stepCountConnectListxD7SA0PYAbMeta || {},
    alias: stepCountConnectListxD7SA0PYAbMeta?.alias || [],
    redirect: stepCountConnectListxD7SA0PYAbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountConnectList.vue").then(m => m.default || m)
  },
  {
    name: stepCountConnectedGCGcDjQytlMeta?.name ?? "stepCountConnected___en",
    path: stepCountConnectedGCGcDjQytlMeta?.path ?? "/stepCountConnected",
    meta: stepCountConnectedGCGcDjQytlMeta || {},
    alias: stepCountConnectedGCGcDjQytlMeta?.alias || [],
    redirect: stepCountConnectedGCGcDjQytlMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountConnected.vue").then(m => m.default || m)
  },
  {
    name: stepCountConnectedGCGcDjQytlMeta?.name ?? "stepCountConnected___tc",
    path: stepCountConnectedGCGcDjQytlMeta?.path ?? "/tc/stepCountConnected",
    meta: stepCountConnectedGCGcDjQytlMeta || {},
    alias: stepCountConnectedGCGcDjQytlMeta?.alias || [],
    redirect: stepCountConnectedGCGcDjQytlMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountConnected.vue").then(m => m.default || m)
  },
  {
    name: stepCountDisconnected45ihMolgNkMeta?.name ?? "stepCountDisconnected___en",
    path: stepCountDisconnected45ihMolgNkMeta?.path ?? "/stepCountDisconnected",
    meta: stepCountDisconnected45ihMolgNkMeta || {},
    alias: stepCountDisconnected45ihMolgNkMeta?.alias || [],
    redirect: stepCountDisconnected45ihMolgNkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountDisconnected.vue").then(m => m.default || m)
  },
  {
    name: stepCountDisconnected45ihMolgNkMeta?.name ?? "stepCountDisconnected___tc",
    path: stepCountDisconnected45ihMolgNkMeta?.path ?? "/tc/stepCountDisconnected",
    meta: stepCountDisconnected45ihMolgNkMeta || {},
    alias: stepCountDisconnected45ihMolgNkMeta?.alias || [],
    redirect: stepCountDisconnected45ihMolgNkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountDisconnected.vue").then(m => m.default || m)
  },
  {
    name: stepCountIntromJiswzOFLYMeta?.name ?? "stepCountIntro___en",
    path: stepCountIntromJiswzOFLYMeta?.path ?? "/stepCountIntro",
    meta: stepCountIntromJiswzOFLYMeta || {},
    alias: stepCountIntromJiswzOFLYMeta?.alias || [],
    redirect: stepCountIntromJiswzOFLYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountIntro.vue").then(m => m.default || m)
  },
  {
    name: stepCountIntromJiswzOFLYMeta?.name ?? "stepCountIntro___tc",
    path: stepCountIntromJiswzOFLYMeta?.path ?? "/tc/stepCountIntro",
    meta: stepCountIntromJiswzOFLYMeta || {},
    alias: stepCountIntromJiswzOFLYMeta?.alias || [],
    redirect: stepCountIntromJiswzOFLYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stepCountIntro.vue").then(m => m.default || m)
  },
  {
    name: stretchC0FH8RSllRMeta?.name ?? "stretch___en",
    path: stretchC0FH8RSllRMeta?.path ?? "/stretch",
    meta: stretchC0FH8RSllRMeta || {},
    alias: stretchC0FH8RSllRMeta?.alias || [],
    redirect: stretchC0FH8RSllRMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stretch.vue").then(m => m.default || m)
  },
  {
    name: stretchC0FH8RSllRMeta?.name ?? "stretch___tc",
    path: stretchC0FH8RSllRMeta?.path ?? "/tc/stretch",
    meta: stretchC0FH8RSllRMeta || {},
    alias: stretchC0FH8RSllRMeta?.alias || [],
    redirect: stretchC0FH8RSllRMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stretch.vue").then(m => m.default || m)
  },
  {
    name: stretchTesty1NqGkd3x0Meta?.name ?? "stretchTest___en",
    path: stretchTesty1NqGkd3x0Meta?.path ?? "/stretchTest",
    meta: stretchTesty1NqGkd3x0Meta || {},
    alias: stretchTesty1NqGkd3x0Meta?.alias || [],
    redirect: stretchTesty1NqGkd3x0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stretchTest.vue").then(m => m.default || m)
  },
  {
    name: stretchTesty1NqGkd3x0Meta?.name ?? "stretchTest___tc",
    path: stretchTesty1NqGkd3x0Meta?.path ?? "/tc/stretchTest",
    meta: stretchTesty1NqGkd3x0Meta || {},
    alias: stretchTesty1NqGkd3x0Meta?.alias || [],
    redirect: stretchTesty1NqGkd3x0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/stretchTest.vue").then(m => m.default || m)
  },
  {
    name: vitalResult3rkaMOsht8Meta?.name ?? "vitalResult___en",
    path: vitalResult3rkaMOsht8Meta?.path ?? "/vitalResult",
    meta: vitalResult3rkaMOsht8Meta || {},
    alias: vitalResult3rkaMOsht8Meta?.alias || [],
    redirect: vitalResult3rkaMOsht8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalResult.vue").then(m => m.default || m)
  },
  {
    name: vitalResult3rkaMOsht8Meta?.name ?? "vitalResult___tc",
    path: vitalResult3rkaMOsht8Meta?.path ?? "/tc/vitalResult",
    meta: vitalResult3rkaMOsht8Meta || {},
    alias: vitalResult3rkaMOsht8Meta?.alias || [],
    redirect: vitalResult3rkaMOsht8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalResult.vue").then(m => m.default || m)
  },
  {
    name: vitalResultDetailnilm0zGab8Meta?.name ?? "vitalResultDetail___en",
    path: vitalResultDetailnilm0zGab8Meta?.path ?? "/vitalResultDetail",
    meta: vitalResultDetailnilm0zGab8Meta || {},
    alias: vitalResultDetailnilm0zGab8Meta?.alias || [],
    redirect: vitalResultDetailnilm0zGab8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalResultDetail.vue").then(m => m.default || m)
  },
  {
    name: vitalResultDetailnilm0zGab8Meta?.name ?? "vitalResultDetail___tc",
    path: vitalResultDetailnilm0zGab8Meta?.path ?? "/tc/vitalResultDetail",
    meta: vitalResultDetailnilm0zGab8Meta || {},
    alias: vitalResultDetailnilm0zGab8Meta?.alias || [],
    redirect: vitalResultDetailnilm0zGab8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalResultDetail.vue").then(m => m.default || m)
  },
  {
    name: vitalScan7wJ7imld4kMeta?.name ?? "vitalScan___en",
    path: vitalScan7wJ7imld4kMeta?.path ?? "/vitalScan",
    meta: vitalScan7wJ7imld4kMeta || {},
    alias: vitalScan7wJ7imld4kMeta?.alias || [],
    redirect: vitalScan7wJ7imld4kMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalScan.vue").then(m => m.default || m)
  },
  {
    name: vitalScan7wJ7imld4kMeta?.name ?? "vitalScan___tc",
    path: vitalScan7wJ7imld4kMeta?.path ?? "/tc/vitalScan",
    meta: vitalScan7wJ7imld4kMeta || {},
    alias: vitalScan7wJ7imld4kMeta?.alias || [],
    redirect: vitalScan7wJ7imld4kMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalScan.vue").then(m => m.default || m)
  },
  {
    name: vitalScanDisDA02hQnFIzMeta?.name ?? "vitalScanDis___en",
    path: vitalScanDisDA02hQnFIzMeta?.path ?? "/vitalScanDis",
    meta: vitalScanDisDA02hQnFIzMeta || {},
    alias: vitalScanDisDA02hQnFIzMeta?.alias || [],
    redirect: vitalScanDisDA02hQnFIzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalScanDis.vue").then(m => m.default || m)
  },
  {
    name: vitalScanDisDA02hQnFIzMeta?.name ?? "vitalScanDis___tc",
    path: vitalScanDisDA02hQnFIzMeta?.path ?? "/tc/vitalScanDis",
    meta: vitalScanDisDA02hQnFIzMeta || {},
    alias: vitalScanDisDA02hQnFIzMeta?.alias || [],
    redirect: vitalScanDisDA02hQnFIzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vitalScanDis.vue").then(m => m.default || m)
  }
]