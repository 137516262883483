import { defineStore } from "pinia";
import appAxios from "~/src/network/app/appAxios";
import appEndpoints from "~/src/network/app/appEndpoints";

export const useAppArticlesStore = defineStore({
  id: "app-articles-store",
  state: () => {
    return {
      articles: {
        loading: false,
        data: [],
        error: null,
      },
      allWellness : {
        loading: false,
        data: [],
        error: null,
      },
      articleDetail: {
        loading: false,
        data: null,
        error: null,
      },
      likeArticle: {
        loading: false,
        data: null,
        error: null,
      },
      bookmarkArticle: {
        loading: false,
        data: null,
        error: null,
      },
      bookmarkedArticles: {
        loading: false,
        data: null,
        error: null,
      }
    };
  },
  actions: {
    async getArticles() {
      try {
        const res = await appAxios.get(appEndpoints.getArticles);
        console.log(res);

        if (res && res.data && res.data.data) {
          this.articles.data = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllWellnessDebug() {
      try {
        const res = await appAxios.get(appEndpoints.getAllWellnessDebug);
        console.log(res);

        if (res && res.data && res.data.data) {
          this.allWellness.data = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllWellness() {
      try {
        const res = await appAxios.get(appEndpoints.getAllWellness);

        if (res && res.data && res.data.data) {
          this.allWellness.data = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getArticleById(id) {
      try {
        this.articleDetail.data = null;
        this.articleDetail.loading = true;
        this.articleDetail.error = null;

        const res = await appAxios.get(`${appEndpoints.getArticleById}/${id}`);
        console.log(res.data.data);

        if (res && res.data && res.data.data) {
          this.articleDetail.data = res.data.data;
        }

        this.articleDetail.loading = false;
        this.articleDetail.error = false;
      } catch (error) {
        console.log(error);
        this.articleDetail.error = error;
        this.articleDetail.loading = true;
      }
    },

    async getBookmarkArticles() {
      try {
        this.bookmarkedArticles.data = null;
        this.bookmarkedArticles.loading = true;
        this.bookmarkedArticles.error = null;

        const res = await appAxios.get(appEndpoints.getBookmarkArticles);
        console.log(res.data.data);

        if (res && res.data && res.data.data) {
          this.bookmarkedArticles.data = res.data.data;
        }

        this.bookmarkedArticles.loading = false;
        this.bookmarkedArticles.error = false;
      } catch (error) {
        console.log(error);
        this.bookmarkedArticles.error = error;
        this.bookmarkedArticles.loading = true;
      }
    },

    async likeArticleById(id, like) {
      try {
        this.likeArticle.data = null;
        this.likeArticle.loading = true;
        this.likeArticle.error = null;
        const res = await appAxios.put(appEndpoints.likeArticleById(id), {
          like,
        });

        console.log("likeArticleById: ", res);

        this.likeArticle.loading = false;
      } catch (error) {
        this.likeArticle.loading = false;
        this.likeArticle.error = error;
      }
    },

    async bookmarkArticleById(id, bookmark) {
      try {
        this.bookmarkArticle.data = null;
        this.bookmarkArticle.loading = true;
        this.bookmarkArticle.error = null;
        const res = await appAxios.put(appEndpoints.bookmarkArticleById(id), {
          bookmark,
        });

        console.log("bookmarkArticleById: ", res);

        this.bookmarkArticle.loading = false;
      } catch (error) {
        this.bookmarkArticle.loading = false;
        this.bookmarkArticle.error = error;
      }
    },
  },
});
