const BE_API_DOMAIN = import.meta.env.VITE_BE_API_DOMAIN
  ? `${import.meta.env.VITE_BE_API_DOMAIN}/`
  : "";
export default {
  // login (sso)
  login: `${BE_API_DOMAIN}api/Login/SSO`,

  // get home page data
  getDashboard: `${BE_API_DOMAIN}api/Dashboard`,

  //post daily task
  postDailyTask: `${BE_API_DOMAIN}api/Task/Daily`,

  //set gender
  updateGender: `${BE_API_DOMAIN}api/User/Update/Gender`,

  //set daily emotion
  postEmotion: `${BE_API_DOMAIN}api/Task/Daily/Emotion`,

  //daily check in
  dailyLogin: `${BE_API_DOMAIN}api/Task/Daily/Login`,

  //task quiz
  taskQuiz: `${BE_API_DOMAIN}api/Task/Quiz`,

  //scan result part
  getVitalScanResult: `${BE_API_DOMAIN}api/Task/PrevVitalResult`,

  //post vital scan result
  postVitalScanData: `${BE_API_DOMAIN}api/Task/VitalScanning`,

  //accept tnc
  postAcceptTnc: `${BE_API_DOMAIN}api/tnc/accept`,

  //check tnc status
  postCheckTncStatus: `${BE_API_DOMAIN}api/tnc/check`,

  //refresh token by refresh token
  refreshToken: `${BE_API_DOMAIN}api/token/refresh`,

  //save today's step count to BE database
  saveStepCount: `${BE_API_DOMAIN}api/step/save`,

  //save login success record to BE
  saveLoginRecord: `${BE_API_DOMAIN}api/Login/Log`,

  //check health data binding
  checkHealthDataBinding: `${BE_API_DOMAIN}api/device/checking`,

  //save device ID for health data binding
  saveHealthDataDeviceID: `${BE_API_DOMAIN}api/device/connect`,

  //remove binding for health data binding
  removeHealthDataBinding: `${BE_API_DOMAIN}api/device/disconnect`,

  //sync past week step count data to earn coins
  syncStepCountDataToEarnCoin: `${BE_API_DOMAIN}api/step/syncback`,

  //get vital scan charts data (past 7 days)
  getVitalScanChartData: `${BE_API_DOMAIN}api/VitalScan/ChartData`,

  //garmin login link, for change location href only
  loginGarmin: `${BE_API_DOMAIN}api/garmin/login?token=`,
};
