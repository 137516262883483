import axios from "axios";
import {
  saveStorage,
  getStorage,
  getSessionStorage,
  configHTMLClass,
} from "~/src/utils/";
import { useCommonStore } from "~/src/stores/common";
import { usePostMessageStore } from "~/src/stores/app";

import beEndpoints from "~/src/network/be/beEndpoints";
import { refreshTokenFn } from "../app/appAxios";
import { useBeAuthStore } from "~/src/stores/be";

const axiosBE = axios.create({
  retry: 3,
  retryDelay: 1000,
});

const errorWhitelist = {
  checkHealthDataBinding: {
    acceptedErrorCode: [101, 102, 103],
  },
};

export const beAccessToken = "BE_ACCESS_TOKEN";
export const beRefreshToken = "BE_REFRESH_TOKEN";

function handleErrorTokenPopup(errorMsg) {
  const commonStore = useCommonStore();
  commonStore.showErrorTokenPopup = true;
  commonStore.errorMsg = errorMsg;
}

axiosBE.interceptors.request.use(
  (config) => {
    config.timeout = 30000;

    config.headers = {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    const TOKEN = getStorage(beAccessToken);

    if (TOKEN) {
      config.headers["Authorization"] = `Bearer ${TOKEN}`;
    }

    config.validateStatus = function (status) {
      // console.log('axios: ', status)
      return status >= 200 && status <= 400; // default
    };

    // add lang to request header
    const urlNeedLang = [
      beEndpoints.login,
      beEndpoints.getDashboard,
      beEndpoints.postDailyTask,
    ];
    if (urlNeedLang.includes(config.url)) {
      let lang = "en";
      if (getSessionStorage("lang") !== "en") {
        lang = "zh";
      }

      config.headers["Lang"] = lang;
    }

    // add freeze class to html & display loading screen
    configHTMLClass({ method: "add" });

    const commonStore = useCommonStore();
    if (config.url !== beEndpoints.login) {
      commonStore.isLoading = true;
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
axiosBE.interceptors.response.use(
  (response) => {
    const { data } = response;
    const commonStore = useCommonStore();

    // remove freeze class to html & remove loading screen
    setTimeout(function () {
      commonStore.isLoading = false;
    }, 1500);

    configHTMLClass({ method: "remove" });

    // const { data, config } = response;
    // const { url } = config;
    // // if has token ,saveToken here
    // if (data.Suceess && data && data.Token) {
    //   const { AccessToken, RefreshToken } = data.Token;
    //   saveToken(AccessTokenName, AccessToken);
    //   saveToken(RefreshTokenName, RefreshToken);
    // }

    // if receive status code 401 -> refresh token start

    //refresh token end

    if (data && data.AccessToken) {
      saveStorage(beAccessToken, data.AccessToken);
    }

    if (data && data.RefreshToken) {
      saveStorage(beRefreshToken, data.RefreshToken);
    }

    if (!data || data.Success === false) {
      const errorInfo = {
        url: response.config.url,
        request: response.config.data,
        response: response.data,
      };

      // alert("outside - if (response.data)" + JSON.stringify(errorInfo));
      for (const [key, value] of Object.entries(errorWhitelist)) {
        if (
          beEndpoints[key] !== errorInfo.url &&
          value.acceptedErrorCode.includes(data.Code)
        ) {
          commonStore.showErrorPopup = true;
          commonStore.errorMsg = errorInfo;
        }
      }

      //send error log to sentry
      window.Sentry.captureException(response);
      throw response;

      // return Promise.reject(errorInfo);
    }

    return response;
  },
  async (error) => {
    const tokenLog = usePostMessageStore();

    //send error log to sentry
    window.Sentry.captureException(error);

    // set only ginny, bill for token refesh
    // const config = error.config;
    // if (!homeStore.home.isTestingAcc) {
    //     return Promise.reject(error);
    // }

    // if receive 502 Network error
    console.log("error " + error, error.code);
    if (error.code == "ERR_NETWORK") {
      handleErrorTokenPopup(error);
      return Promise.reject(error);
    }

    console.log("error.response.status: " + error.response?.status);
    const config = error.config;
    config.__retryCount = config.__retryCount || 0;

    // if token (BE) expire -> refresh token
    if (error.response?.status === 401 && config.__retryCount < config.retry) {
      tokenLog.pushTokenLog({
        action: "response 401 refresh token start",
        data: { retry: config.__retryCount, url: error.config.url },
      });

      // alert("refresh token start");
      console.log("refresh token start");
      const refreshToken = getStorage(beRefreshToken);
      const params = {
        RefreshToken: refreshToken,
      };

      try {
        const { data } = await axiosBE.post(beEndpoints.refreshToken, params);

        if (data && data.AccessToken) {
          console.log("saved beAccessToken", data.AccessToken);
          saveStorage(beAccessToken, data.AccessToken);
        }
        if (data && data.RefreshToken) {
          console.log("saved beRefreshToken", data.RefreshToken);
          saveStorage(beRefreshToken, data.RefreshToken);
        }
        // retry request
        config.headers["Authorization"] = `Bearer ${data.AccessToken}`;
        // alert("refresh token success");
        console.log("refresh token success");
        tokenLog.pushTokenLog({ action: "refresh token success" });

        config.__retryCount += 1;

        tokenLog.pushTokenLog({
          action: "resume org api call",
          url: error.config.url,
        });

        return axiosBE.request(config);
      } catch (error) {
        console.error(error);
        handleErrorTokenPopup(error);
        window.Sentry.captureException("BE refresh token failed");
        return Promise.reject(error);
      }
    }

    // if 403 (app token expire), refresh app token (refreshTokenFn) and get new access token and refresh token by refreshTokenFn, then request again
    else if (
      error.response?.status === 403 &&
      config.__retryCount < config.retry
    ) {
      tokenLog.pushTokenLog({
        action: "response 403 App refresh token start",
        data: { retry: config.__retryCount, url: error.config.url },
      });

      // await refreshTokenFn(); // refresh app token
      // await useBeAuthStore().beLogin(); // refresh be token
      // const config = error.config;
      // return axiosBE.request(config);
      // rewirte code with try catch
      console.log("error.response.status === 403");
      try {
        await refreshTokenFn("from be 403"); // refresh app token
        await useBeAuthStore().beLogin(); // refresh be token
        const config = error.config;
        config.__retryCount += 1;
        return axiosBE.request(config);
      } catch (err) {
        console.error(err);
        tokenLog.pushTokenLog({
          action: "response 403 App refresh token fail",
          data: error,
        });
        handleErrorTokenPopup(err);
        window.Sentry.captureException("app refresh token failed");
        return Promise.reject(err);
      }
    } else {
      // alert(JSON.stringify(error))
      const commonStore = useCommonStore();
      // alert("error.response.status: " + error.response.status);
      console.log("error.response.status: ", error.response?.status);

      if (error.response?.status === 401 || error.response?.status === 403) {
        handleErrorTokenPopup(error);
      } else {
        commonStore.showErrorPopup = true;
        commonStore.errorMsg = error;
        commonStore.isLoading = false;
        configHTMLClass({ method: "remove" });
        console.error(error);
      }

      // rollbar.error(error);

      return Promise.reject(error);
    }
  }
);

export default axiosBE;
