export const Device = {
  isAndroid: navigator.userAgent.toLowerCase().indexOf("android") > -1,
  isIos: navigator.userAgent.toLowerCase().indexOf("iphone") > -1,
  isDesktop:
    navigator.userAgent.toLowerCase().indexOf("iphone") === -1 &&
    navigator.userAgent.toLowerCase().indexOf("android") === -1,
};

export const messageListener = (
  callback: (type: string, params: any) => void
): void => {
  (Device.isAndroid ? document : window).addEventListener(
    "message",
    ({ data: payload }: any) => {
      const { type, params } =
        typeof payload === "object" ? payload : JSON.parse(payload);
      callback(type, params);
    }
  );
};

interface ReactNativeWindow extends Window {
  ReactNativeWebView?: {
    postMessage: (body: string) => void;
  };
}
declare const window: ReactNativeWindow;
interface IPostMessagePayload {
  type: string;
  [key: string]: unknown;
}
export const postMessage = async (body: IPostMessagePayload): Promise<any> => {
  window.ReactNativeWebView?.postMessage(JSON.stringify(body));

  if (body.type !== "NAVIGATE_BACK") {
    console.log("send postMessage " + JSON.stringify(body));
  }

  return new Promise((resolve, reject) => {
    messageListener((type, params) => {
      if (type === body.type) {
        resolve(params);
      } else {
        reject(new Error("Invalid message type " + type + " " + body.type));
      }
    });
  });
};
