import { defineStore } from "pinia";


export const useCommonStore = defineStore({
    id: "common-store",
    state: () => {
        return {
            showErrorTokenPopup: false,
            showErrorPopup: false,
            showGarminErrorPopup: false,
            errorMsg: "",
            isLoading: false,
        };
    },
    actions: {

    },
});
