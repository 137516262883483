import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { Router } from "vue-router";

declare global {
  interface Window {
    Sentry: any;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const vueRouter = nuxtApp.$router as Router;

  Sentry.init({
    app: [vueApp],
    dsn: "",
    environment: "UAT",
    enabled: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
      timeout: 500,
      hooks: ["activate", "mount", "update"],
    },
    attachProps: true,
    logErrors: true, // keep natural logError function
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      // Continue sending to Sentry
      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "mount", "update"],
    })
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });
  window.Sentry = Sentry;

  return {
    provide: {
      sentry: Sentry,
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
