// import Rollbar from "rollbar";
import dayjs from "dayjs";

export const getLang = () => {
    const path = window.location.pathname;

    const langs = ["en", "tc"];

    for (let i = 0; i < langs.length; i++) {
        const lang = langs[i];
        if (path.indexOf(`/${lang}`) > -1) {
            return lang;
        }
    }
    return langs[0];
};

// anchor to the top of target element
export const anchorTo = (ele, anchorSpace = true) => {
    const header = document.getElementsByClassName("header")[0];
    const offsetTop = ele.getClientRects()[0].top

    const pos = typeof header !== 'undefined' ?
        window.pageYOffset + offsetTop - header.offsetHeight : window.pageYOffset + offsetTop

    const extraSpace = anchorSpace ? 10 : 0;

    window.scrollTo({ top: pos - extraSpace });
}

export const configHTMLClass = ({ method = "add" }) => {
    const htmlTag = document.getElementsByTagName('html')[0];
    if (method == "add") {
        htmlTag.classList.add('freeze');
    }

    else {
        htmlTag.classList.remove('freeze');
    }
}

export const lastPopupFunc = () => {
    if (document.querySelectorAll('.popup').length <= 1) {
        const htmlTag = document.getElementsByTagName('html')[0];
        htmlTag.classList.remove('freeze');
    }
}

export const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const dateFormat = ({ lang, dataDate, shortTerm = false, unix = false }) => {
    if (!shortTerm) {
        if (lang == "en") {
            return dayjs(dataDate).format("DD MMM YYYY, hh:mm A");
        } else {
            return dayjs(dataDate).format(
                "YYYY年MM月DD日 hh:mm A"
            );
        }
    }

    if (shortTerm && unix) {
        if (lang == "en") {
            return dayjs.unix(dataDate / 1000).format("DD MMM");
        } else {
            return dayjs.unix(dataDate / 1000).format(
                "MM月DD日"
            );
        }
    }
}

export const chartDateFormat = ({ lang, dataDate }) => {
    console.log('chartDateFormat', dataDate, dayjs.unix(dataDate).format("DD MMM"))
    if (lang == "en") {
        return dayjs.unix(dataDate / 1000).format("DD MMM");
    } else {
        return dayjs.unix(dataDate / 1000).format(
            "DD/M"
        );
    }
}

/*
convert chart series format from datetime [[1694576062000, 66.5], [1694669664000, 66.4]]
to category [['1694576062000', 66.5], ['1694669664000', 66.4]]
*/

export const chartDataTypeConvert = ({ chartData }) => {
    console.log('chartDataTypeConvert ', chartData)
    chartData.forEach(subArr => {
        if (typeof subArr !== 'string')
            subArr[0] = subArr[0].toString();
    });
    console.log('chartDataTypeConvert after convert', chartData)

    return chartData;
}

// const ROLLBAR_CLIENT_TOKEN = import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN;
// export const rollbar = new Rollbar({
//     accessToken: ROLLBAR_CLIENT_TOKEN,
//     captureUncaught: true,
//     captureUnhandledRejections: true,
//     payload: {
//         // Track your events to a specific version of code for better visibility into version health
//         code_version: "1.0.0",
//         // Add custom data to your events by adding custom key/value pairs like the one below
//         custom_data: "foo",
//     },
// });