import { defineStore } from "pinia";
import beAxios from "~/src/network/be/beAxios";
import beEndpoints from "~/src/network/be/beEndpoints";

export const useVitalScanStore = defineStore({
  id: "be-vital-scan-store",
  state: () => {
    return {
      vitalScan: {
        loading: false,
        data: null,
        error: null,
      },

      submitVitalResult: {
        loading: false,
        error: null,
      },

      vitalScanLevels: [
        {
          dataName: "holisticHealth.generalWellness",
          label: "generalWellness",
          single: true,
          level: [
            {
              val: 40,
              include: false,
              txt: "Poor",
              color: "red",
              icon: "Worse",
              ranges: ["< 40"],
              suggestions: {
                title: "vitalResult.suggestions.generalWellness.Poor.title",
                desc: "vitalResult.suggestions.generalWellness.Poor.desc",
                sections: ["breath", "water"],
              },
            }, // <40 < > ≤ ≥
            {
              val: 80.9,
              include: true,
              txt: "Good",
              color: "black",
              icon: "Good",
              ranges: ["40 - 80.9"],
              suggestions: {
                title: "vitalResult.suggestions.generalWellness.Good.title",
                desc: "vitalResult.suggestions.generalWellness.Good.desc",
                sections: ["stretch"],
              },
            }, // <=40 - 80.9<=
            {
              val: 100,
              include: true,
              txt: "Excellent",
              color: "green",
              icon: "Excellent",
              ranges: ["> 80.9"],
              suggestions: {
                title: "vitalResult.suggestions.generalWellness.Excellent.title",
                desc: "vitalResult.suggestions.generalWellness.Excellent.desc",
                sections: ["breath", "music"],
              },
            }, // 80.9 < - 100<=
          ],
          changesRules: {
            type: "single",
            colorChangeRules: [
              {
                trend: "increase",
                color: "green",
              },
              {
                trend: "decrease",
                color: "red",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },
        {
          dataName: "vitalSigns.stress",
          label: "stress",
          level: [
            {
              val: 2,
              include: true,
              txt: "Excellent",
              color: "green",
              icon: "Excellent",
              ranges: ["≤ 2"],
              order: 3,
              suggestions: {
                title: "vitalResult.suggestions.stress.Excellent.title",
                desc: "vitalResult.suggestions.stress.Excellent.desc",
                sections: ["music", "water"],
              },
            }, // <=2 // <40 < > ≤ ≥
            {
              val: 3,
              include: true,
              txt: "Good",
              color: "green",
              icon: "Good",
              ranges: [" 2.1 - 3"],
              order: 2,
              suggestions: {
                title: "vitalResult.suggestions.stress.Good.title",
                desc: "vitalResult.suggestions.stress.Good.desc",
                sections: ["breath", "music"],
              },
            }, // <2 - 3<=
            {
              val: 1000,
              include: true,
              txt: "Poor",
              color: "red",
              icon: "Bad",
              ranges: [" > 3"],
              order: 1,
              suggestions: {
                title: "vitalResult.suggestions.stress.Poor.title",
                desc: "vitalResult.suggestions.stress.Poor.desc",
                sections: ["breath", "stretch", "water"],
              },
            }, // >3
          ],
          changesRules: {
            type: "single",
            colorChangeRules: [
              {
                trend: "increase",
                color: "red",
              },
              {
                trend: "decrease",
                color: "green",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },
        {
          dataName: "vitalSigns.respiratoryRate",
          label: "respiratoryRate",
          unit: "respiratoryRateUnit",
          level: [
            {
              val: 12,
              include: false,
              txt: "BelowAverage",
              color: "red",
              icon: "Worse",
              ranges: ["< 12"],
              suggestions: {
                title: "vitalResult.suggestions.respiratoryRate.BelowAverage.title",
                desc: "vitalResult.suggestions.respiratoryRate.BelowAverage.desc",
                sections: ["breath"],
              },
            }, // <12
            {
              val: 20,
              include: true,
              txt: "Excellent",
              color: "green",
              icon: "Excellent",
              ranges: ["12 - 20"],
              suggestions: {
                title: "vitalResult.suggestions.respiratoryRate.Excellent.title",
                desc: "vitalResult.suggestions.respiratoryRate.Excellent.desc",
                sections: ["breath", "stretch"],
              },
            }, // <=12 - 20<=20
            {
              val: 100,
              include: false,
              txt: "AboveAverage",
              color: "red",
              icon: "Worse",
              ranges: ["> 20"],
              suggestions: {
                title: "vitalResult.suggestions.respiratoryRate.AboveAverage.title",
                desc: "vitalResult.suggestions.respiratoryRate.AboveAverage.desc",
                sections: ["breath"],
              },
            }, // > 20
          ],
          changesRules: {
            type: "multiple",
            colorChangeRules: [
              {
                trend: "increase",
                condition: {
                  sign: "gt",
                  value: 20,
                },
                color: "red",
              },
              {
                trend: "decrease",
                condition: {
                  sign: "lt",
                  value: 12,
                },
                color: "red",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },
        {
          dataName: "vitalSigns.spo2",
          label: "spo2",
          unit: "spo2Unit",
          level: [
            {
              val: 95,
              include: false,
              txt: "BelowAverage",
              color: "red",
              icon: "Worse",
              ranges: ["< 95"],
              suggestions: {
                title: "vitalResult.suggestions.spo2.BelowAverage.title",
                desc: "vitalResult.suggestions.spo2.BelowAverage.desc",
                sections: ["music", "water"],
              },
            }, // <95
            {
              val: 100,
              include: true,
              txt: "Normal",
              color: "green",
              icon: "Good",
              ranges: ["95 - 100"],
              suggestions: {
                title: "vitalResult.suggestions.spo2.Normal.title",
                desc: "vitalResult.suggestions.spo2.Normal.desc",
                sections: ["music", "water"],
              },
            }, // <=95 - 100
          ],
          changesRules: {
            type: "single",
            colorChangeRules: [
              {
                trend: "increase",
                color: "green",
              },
              {
                trend: "decrease",
                color: "red",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },
        {
          dataName: "vitalSigns.heartRate",
          label: "heartRate",
          unit: "heartRateUnit",
          level: [
            {
              val: 60,
              include: false,
              txt: "BelowAverage",
              color: "red",
              icon: "Worse",
              ranges: ["< 60"],
              suggestions: {
                title: "vitalResult.suggestions.heartRate.BelowAverage.title",
                desc: "vitalResult.suggestions.heartRate.BelowAverage.desc",
                sections: ["stretch"],
              },
            }, // <60
            {
              val: 100,
              include: true,
              txt: "Normal",
              color: "green",
              icon: "Good",
              ranges: ["60 - 100"],
              suggestions: {
                title: "vitalResult.suggestions.heartRate.Normal.title",
                desc: "vitalResult.suggestions.heartRate.Normal.desc",
                sections: ["water"],
              },
            }, // <=60 - 100<=
            {
              val: 1000,
              include: true,
              txt: "AboveAverage",
              color: "red",
              icon: "Worse",
              ranges: ["> 100"],
              suggestions: {
                title: "vitalResult.suggestions.heartRate.AboveAverage.title",
                desc: "vitalResult.suggestions.heartRate.AboveAverage.desc",
                sections: ["stretch", "music"],
              },
            }, // <100 - 1000
          ],
          changesRules: {
            type: "multiple",
            colorChangeRules: [
              {
                trend: "increase",
                condition: {
                  sign: "gt",
                  value: 100,
                },
                color: "red",
              },
              {
                trend: "decrease",
                condition: {
                  sign: "lt",
                  value: 60,
                },
                color: "red",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },
        {
          dataName: "vitalSigns.hrvSdnn",
          label: "hrvSdnn",
          unit: "hrvSdnnUnit",
          level: [
            {
              val: 10.8,
              include: false,
              txt: "AtRisk",
              color: "red",
              icon: "Worse",
              ranges: ["≤ 10.8"],
              suggestions: {
                title: "vitalResult.suggestions.hrvSdnn.AtRisk.title",
                desc: "vitalResult.suggestions.hrvSdnn.AtRisk.desc",
                sections: ["breath", "stretch", "music", "water"],
              },
            }, // <10.8
            {
              val: 16.4,
              include: true,
              txt: "Poor",
              color: "red",
              icon: "Bad",
              ranges: ["10.9 - 16.4"],
              suggestions: {
                title: "vitalResult.suggestions.hrvSdnn.Poor.title",
                desc: "vitalResult.suggestions.hrvSdnn.Poor.desc",
                sections: ["breath", "stretch", "music", "water"],
              },
            }, // <=10.8 - 16.4<
            {
              val: 35.0,
              include: false,
              txt: "BelowAverage",
              color: "red",
              icon: "Bad",
              ranges: ["16.5 - 35"],
              suggestions: {
                title: "vitalResult.suggestions.hrvSdnn.BelowAverage.title",
                desc: "vitalResult.suggestions.hrvSdnn.BelowAverage.desc",
                sections: ["breath", "stretch", "music", "water"],
              },
            }, // <=16.4 - 35.0<
            {
              val: 51.5,
              include: true,
              txt: "Good",
              color: "green",
              icon: "Good",
              ranges: ["35.1 - 51.5"],
              suggestions: {
                title: "vitalResult.suggestions.hrvSdnn.Good.title",
                desc: "vitalResult.suggestions.hrvSdnn.Good.desc",
                sections: ["breath", "stretch", "music", "water"],
              },
            }, // <=35.0 - 51.5<=
            {
              val: 500,
              include: true,
              txt: "Excellent",
              color: "green",
              icon: "Excellent",
              ranges: ["> 51.5"],
              suggestions: {
                title: "vitalResult.suggestions.hrvSdnn.Excellent.title",
                desc: "vitalResult.suggestions.hrvSdnn.Excellent.desc",
                sections: ["breath", "stretch", "music", "water"],
              },
            }, // <51.5 - 300
          ],
          changesRules: {
            type: "single",
            colorChangeRules: [
              {
                trend: "increase",
                color: "green",
              },
              {
                trend: "decrease",
                color: "red",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },

        {
          label: "bloodPressure",
          unit: "bloodPressureUnit",
          type: "multi",
          dataName: {
            SBP: "vitalSigns.bloodPressureSystolic",
            DBP: "vitalSigns.bloodPressureDiastolic",
          },
          popupTitlesKey: [
            "vitalResult.range",
            "vitalResult.range",
            "vitalResult.condition",
          ],
          level: [
            {
              cond: [
                [{ field: "SBP", opt: "gtEq", value: 140 }],
                [{ field: "DBP", opt: "gtEq", value: 90 }],
              ],

              condKeys: [
                ["SBP ≥ 140", "ANY"],
                ["ANY", "SBP ≥ 90"],
              ],

              txt: "Hypertension",
              color: "red",
              icon: "Worse",
              suggestions: {
                title: "vitalResult.suggestions.bloodPressure.Hypertension.title",
                desc: "vitalResult.suggestions.bloodPressure.Hypertension.desc",
                sections: ["water", "stretch"],
              },
              // ranges: ["SBP ≥ 140 & DBP = Any", "SBP ≥ Any & DBP ≥ 90"],
            } /*"SBP ≥ 140 & DBP = Any OR SBP ≥ Any & DBP ≥ 90"*/,

            {
              cond: [
                [
                  { field: "SBP", opt: "between", value: [130, 139] },
                  { field: "DBP", opt: "ltEq", value: 89 },
                ],
                [
                  { field: "SBP", opt: "ltEq", value: 139 },
                  { field: "DBP", opt: "between", value: [85, 89] },
                ],
              ],

              condKeys: [
                ["130 - 139", "≤ 89"],
                ["≤ 139", "85 - 89"],
              ],

              txt: "HighNormal",
              color: "red",
              icon: "Bad",
              suggestions: {
                title: "vitalResult.suggestions.bloodPressure.HighNormal.title",
                desc: "vitalResult.suggestions.bloodPressure.HighNormal.desc",
                sections: ["water", "stretch"],
              },
              // ranges: [
              //   "SBP = 130 - 139 & DBP ≤ 89",
              //   "SBP <=139 & DBP = 85 - 89",
              // ],
            } /*"SBP = 130 - 139 & DBP ≤ 89 OR SBP <=139 & DBP = 85 - 89" */,

            {
              cond: [
                [
                  { field: "SBP", opt: "between", value: [120, 129] },
                  { field: "DBP", opt: "ltEq", value: 84 },
                ],
                [
                  { field: "SBP", opt: "ltEq", value: 129 },
                  { field: "DBP", opt: "between", value: [80, 84] },
                ],
              ],
              condKeys: [
                ["120 - 129", "≤ 84"],
                ["≤ 129", "80 - 84"],
              ],
              txt: "Normal",
              color: "black",
              icon: "Good",
              suggestions: {
                title: "vitalResult.suggestions.bloodPressure.Normal.title",
                desc: "vitalResult.suggestions.bloodPressure.Normal.desc",
                sections: ["stretch"],
              },
              // ranges: [
              //   "SBP = 120 - 129 & DBP ≤ 84",
              //   "SBP<=129 & DBP = 80 - 84",
              // ],
            } /* "SBP = 120 - 129 & DBP ≤ 84 OR SBP<=129 & DBP = 80 - 84" */,

            {
              cond: [
                [
                  { field: "SBP", opt: "lt", value: 120 },
                  { field: "DBP", opt: "lt", value: 80 },
                ],
              ],
              condKeys: [["< 120", "< 80"]],
              txt: "Optimal",
              color: "green",
              icon: "Excellent",
              suggestions: {
                title: "vitalResult.suggestions.bloodPressure.Optimal.title",
                desc: "vitalResult.suggestions.bloodPressure.Optimal.desc",
                sections: ["breath", "music"],
              },
              // ranges: ["SBP <120 & DBP <80"],
            }, // SBP <120 & DBP <80
          ],
          changesRules: {
            type: "single",
            colorChangeRules: [
              {
                trend: "increase",
                color: "red",
              },
              {
                trend: "decrease",
                color: "green",
              },
              {
                trend: "noChange",
                color: "black",
              },
            ],
          },
        },
      ],
      vitalScanGrading: [],

      vitalScanChartData: {
        loading: false,
        data: null,
        error: null
      },

      vitalScanChartDataFE: {
        heartRate: [],
        spo2: [],
        ibi: [],
        respiratoryRate: [],
        stress: [],
        stressScore: [],
        hrvSdnn: [],
        hrvRmssd: [],
        bloodPressure: [],
        bloodPressureSystolic: [],
        bloodPressureDiastolic: [],
        generalWellness: []
      } //for generate chart, format: [[date, value],[date, value]]
    };
  },

  actions: {
    async getVitalScanData() {
      try {
        this.vitalScan.loading = true;
        this.vitalScan.error = null;

        const { data } = await beAxios.get(beEndpoints.getVitalScanResult);
        console.log(data);

        this.vitalScan.loading = false;
        this.vitalScan.data = data.Data;
      } catch (error) {
        this.vitalScan.loading = false;
        this.vitalScan.error = error;
      }
    },
    async postVitalScanData(resultData) {
      try {
        this.submitVitalResult.loading = true;
        this.submitVitalResult.error = null;

        const params = resultData;
        const { data } = await beAxios.post(beEndpoints.postVitalScanData, params);
        console.log(data);

        this.submitVitalResult.loading = false;
        this.submitVitalResult.error = data;
      } catch (error) {
        this.submitVitalResult.loading = false;
        this.submitVitalResult.error = error;
      }
    },
    async getVitalScanChartData() {
      try {
        this.vitalScanChartData.loading = true;
        this.vitalScanChartData.error = null;
        for (const arr in this.vitalScanChartDataFE) {
          this.vitalScanChartDataFE[arr] = [];
        }

        const { data } = await beAxios.get(beEndpoints.getVitalScanChartData);

        this.vitalScanChartData.loading = false;
        this.vitalScanChartData.data = data.Data;
        this.vitalScanChartDataFE.ShowButton = data.Data.ShowButton;
        this.vitalScanChartDataFE.ShowChart = data.Data.ShowChart;

        if (data.Data) {
          const vitalSigns = Object.keys(data.Data.ChartData[0]?.Result?.vitalSigns);

          if (vitalSigns) {
            data.Data.ChartData?.forEach((scanResult) => {
              const unixDate = new Date(scanResult.Date).getTime();

              vitalSigns.forEach((sign) => {
                let val = scanResult.Result.vitalSigns[sign];
                let roundUpVal = 0;

                if (typeof val !== 'string' && typeof val !== 'undefined') {
                  roundUpVal = Number(val.toFixed(1));
                }
                if (typeof val == 'string') {
                  roundUpVal = val;
                }

                const dayResult = [unixDate, roundUpVal];

                if (this.vitalScanChartDataFE[sign])
                  this.vitalScanChartDataFE[sign].push(dayResult);
              })

              this.vitalScanChartDataFE.generalWellness.push(
                [unixDate, Number(scanResult.Result.holisticHealth.generalWellness.toFixed(1))]
              );
            })
          }
        }
      } catch (error) {
        this.vitalScanChartData.loading = false;
        this.vitalScanChartData.error = error;
      }
    },
  },
});