<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { Device, getSessionStorage } from "~/src/utils/";
import { usePostMessageStore } from "~/src/stores/app/";
import { useBeHomeStore } from "~/src/stores/be/";

export default {
  name: "App",
  mounted() {
    this.init();
    this.setupInset();
    this.setupDocHeight();
  },

  beforeUnmount() {
    const _this = this;
    (Device.isAndroid ? document : window).removeEventListener(
      "message",
      _this.handleMesage
    );
  },
  computed: {
    ...mapState(useBeHomeStore, ["home"]),
    homeData() {
      if (!this.home.data || this.home.data.length <= 0) {
        return {};
      }

      return this.home.data[0];
    },
  },
  methods: {
    ...mapActions(usePostMessageStore, [
      "updatePostLog",
      "updatejwtToken",
      "pushTokenLog",
    ]),
    init() {
      const _this = this;
      (Device.isAndroid ? document : window).addEventListener(
        "message",
        _this.handleMesage
      );
    },

    setupInset() {
      const { top, bottom, left, right } = getSessionStorage("safeAreaInsets")
        ? JSON.parse(getSessionStorage("safeAreaInsets"))
        : {};

      const root = document.querySelector(":root");
      root.style.setProperty("--inset-top", `${top || 0}px`);
      root.style.setProperty("--inset-bottom", `${bottom || 0}px`);
      root.style.setProperty("--inset-left", `${left || 0}px`);
      root.style.setProperty("--inset-right", `${right || 0}px`);
    },
    setupDocHeight() {
      const root = document.querySelector(":root");
      root.style.setProperty("--doc-height", `${window.innerHeight}px`);
    },

    handleMesage({ data: payload }) {
      const { type, params } =
        typeof payload === "object" ? payload : JSON.parse(payload);
      if (type && params) {
        this.updatePostLog({ type, params });
      }

      if (type == "HYDRATE_STORE") {
        this.pushTokenLog({
          action: "update app token from listener",
          data: { type, params },
        });
      }
    },

    dataLayerPush() {
      if (typeof this.homeData?.User?.User_UUID !== "undefined") {
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: "balance_user_property",
          balance_user_id: this.homeData.User.User_UUID,
        });
      }
    },
  },
  watch: {
    $route() {
      this.dataLayerPush();
    },
    "homeData.User.User_UUID"(val) {
      // eslint-disable-next-line no-undef
      dataLayer.push({
        event: "balance_user_property",
        balance_user_id: val,
      });
    },
  },
};
</script>
