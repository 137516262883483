import { defineStore } from "pinia";
import beAxios from "~/src/network/be/beAxios";
import beEndpoints from "~/src/network/be/beEndpoints";

export const useTncCheckingStore = defineStore({
  id: "be-tnc-checking-store",
  state: () => {
    return {
      acceptTnc: {
        loading: false,
        error: null,
      },
      checkTncEntryStatus: {
        loading: false,
        data: null,
        error: null,
      },
      checkTncScanStatus: {
        loading: false,
        data: null,
        error: null,
      },
    };
  },

  actions: {
    async postAcceptTnc(tncType) {
      try {
        this.acceptTnc.loading = true;
        this.acceptTnc.error = null;

        const params = tncType;
        const { data } = await beAxios.post(beEndpoints.postAcceptTnc, params);
        console.log(data);

        this.acceptTnc.loading = false;
        this.acceptTnc.error = data;
      } catch (error) {
        this.acceptTnc.loading = false;
        this.acceptTnc.error = error;
      }
    },
    async postCheckTncStatus(tncType) {
      try {
        this.checkTncScanStatus.loading = true;
        this.checkTncEntryStatus.loading = true;
        this.checkTncScanStatus.error = null;
        this.checkTncEntryStatus.error = null;

        const params = tncType;
        const { data } = await beAxios.post(beEndpoints.postCheckTncStatus, params);
        console.log(data);

        this.checkTncScanStatus.loading = false;
        this.checkTncEntryStatus.loading = false;

        if (tncType.tnc_type === 'scan') {
          this.checkTncScanStatus.data = data.Data;
        } else if (tncType.tnc_type === 'entry_agree'){
          this.checkTncEntryStatus.data = data.Data;
        }
      } catch (error) {
        this.checkTncScanStatus.loading = false;
        this.checkTncEntryStatus.loading = false;
        this.checkTncScanStatus.error = error;
        this.checkTncEntryStatus.error = error;
      }
    },
  },
});