import { defineStore } from "pinia";
import beAxios from "~/src/network/be/beAxios";
import beEndpoints from "~/src/network/be/beEndpoints";

export const useStepCountStore = defineStore({
    id: "step-count-store",
    state: () => {
        return {
            checkBinding: {
                data: {},
                success: false,
                bindingErrorCode: null,
            },
            saveBinding: {
                data: {},
                success: false,
            },
            removeBinding: {
                data: {},
                success: false,
            },
            syncStepData: {
                data: {},
                success: false,
            },
            stepCountPlatform: null,
            nonNativeStepCountInfo: {
                garmin: 0,
            }
        }
    },
    actions: {
        async checkHealthDataBinding(deviceID) {
            try {
                this.checkBinding.success = false;
                this.checkBinding.bindingErrorCode = null;

                const { data } = await beAxios.post(beEndpoints.checkHealthDataBinding, { DeviceID: deviceID });
                this.checkBinding.data = data;

                this.checkBinding.success = true;
            } catch (error) {
                this.checkBinding.success = false;
                this.checkBinding.data = error.data;

                if (this.checkBinding.data.Success == false && this.checkBinding.data.Code == 101 || this.checkBinding.data.Code == 102) {
                    this.checkBinding.bindingErrorCode = this.checkBinding.data.Code;
                }
            }
        },
        async saveHealthDataDeviceID(deviceID) {
            try {
                this.saveBinding.success = false;
                const { data } = await beAxios.post(beEndpoints.saveHealthDataDeviceID, { DeviceID: deviceID });
                this.saveBinding.data = { data };
                this.saveBinding.success = true;
            } catch (error) {
                this.saveBinding.success = false;
                console.log(error);
            }
        },
        async removeHealthDataBinding() {
            try {
                this.removeBinding.success = false;
                const { data } = await beAxios.post(beEndpoints.removeHealthDataBinding);
                this.removeBinding.data = { data };
                this.removeBinding.success = true;

            } catch (error) {
                this.removeBinding.success = false;
                console.log(error);
            }
        },
        async syncStepCountDataToEarnCoin(stepCountData) {
            try {
                console.log('syncStepCountDataToEarnCoin', stepCountData)
                this.syncStepData.success = false;
                const { data } = await beAxios.post(beEndpoints.syncStepCountDataToEarnCoin, { data: stepCountData });
                this.syncStepData.data = data.TotalPoint;
                this.syncStepData.success = true;

            } catch (error) {
                this.syncStepData.success = false;
                console.log(error);
            }
        },
    }
})