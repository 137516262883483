import { defineStore } from "pinia";
import beAxios from "~/src/network/be/beAxios";
import beEndpoints from "~/src/network/be/beEndpoints";

export const useBeHomeStore = defineStore({
  id: "be-home-store",
  state: () => {
    return {
      home: {
        loading: false,
        data: [],
        error: null,
        isTestingAcc: false,
      },
      dailyTask: {
        water: false,
        playlist: false,
        success: false,
        data: {},
      },
      genderSetting: {
        success: false,
        data: {},
      },
      emotionSetting: {
        success: false,
        data: {},
      },
      dailyLoginTask: {
        success: false,
        data: {},
      },
      taskQuiz: {
        success: false,
        data: {},
      },
      stepCountSaving: {
        success: false,
        data: {},
      },
      hasReadHomeTut: true,
    };
  },
  actions: {
    async getHomeData() {
      try {
        const { data } = await beAxios.get(beEndpoints.getDashboard);
        console.log("getHomeData: ", data);

        if (data.Data && data.Data[0] && data.Data[0].TaskDetails) {
          const taskDetail = data.Data[0].TaskDetails;

          Object.keys(taskDetail).forEach((key) => {
            const task = taskDetail[key];
            task.Completed = task.Count >= task.TotalCount;
          });
        }

        if (data.Data && data.Data[0] && data.Data[0].User) {
          if (data.Data[0].User.ConnectedPlatform == "") {
            data.Data[0].User.ConnectedPlatform = "google/apple";
          }
        }

        this.home.data = data.Data;

        const testingAccs = ["Ginny Kong", "Bill Yu", "Zlashies Zlashy"];

        if (
          this.home.data &&
          this.home.data.length > 0 &&
          this.home.data[0].User &&
          this.home.data[0].User.Name
        ) {
          this.home.isTestingAcc = testingAccs.includes(
            this.home.data[0].User.Name
          );
        }
      } catch (error) {
        console.log("getHomeData Error: ", error);
      }
    },

    async postDailyTask({ Action, Data = {} }) {
      try {
        this.dailyTask.success = false;

        Object.keys(this.dailyTask).forEach((key) => {
          this.dailyTask[key] = false;
        });

        const params = { Action, Data };

        const { data } = await beAxios.post(beEndpoints.postDailyTask, params);
        this.dailyTask.data = { data };

        this.dailyTask[Action] = true;
        this.dailyTask.success = true;
      } catch (error) {
        Object.keys(this.dailyTask).forEach((key) => {
          this.dailyTask[key] = false;
        });
        this.dailyTask.success = false;

        console.log(error);
        throw new Error("data false though error", error);
      }
    },

    async postEmotion({ Emotion = {} }) {
      try {
        const params = { Emotion: Emotion };

        this.emotionSetting.success = false;
        const { data } = await beAxios.post(beEndpoints.postEmotion, params);
        this.emotionSetting.data = { data };

        this.emotionSetting.success = true;
      } catch (error) {
        this.emotionSetting.success = false;
        console.log(error);
      }
    },

    async setGender(gender) {
      try {
        this.genderSetting.success = false;

        const { data } = await beAxios.post(beEndpoints.updateGender, {
          Data: gender,
        });
        this.genderSetting.data = { data };

        this.genderSetting.success = true;
      } catch (error) {
        this.genderSetting.success = false;
        console.log(error);
      }
    },

    async dailyLogin() {
      try {
        this.dailyLoginTask.success = false;

        const { data } = await beAxios.post(beEndpoints.dailyLogin);
        this.dailyLoginTask.data = { data };

        this.dailyLoginTask.success = true;
        this.getHomeData();
      } catch (error) {
        this.dailyLoginTask.success = false;
        console.log(error);
      }
    },

    async postQuiz(params) {
      try {
        this.taskQuiz.success = false;

        console.log({ ...params });

        const { data } = await beAxios.post(beEndpoints.taskQuiz, {
          ...params,
        });
        this.taskQuiz.data = { data };

        this.taskQuiz.success = true;
      } catch (error) {
        this.taskQuiz.success = false;
        console.log(error);
      }
    },

    async saveStepCount(stepAmount) {
      try {
        this.stepCountSaving.success = false;

        const { data } = await beAxios.post(beEndpoints.saveStepCount, {
          StepCount: stepAmount,
        });
        this.stepCountSaving.data = { data };

        this.stepCountSaving.success = true;
      } catch (error) {
        this.stepCountSaving.success = false;
        console.log(error);
      }
    },
  },
});
