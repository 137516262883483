export const getStorage = (name) => {
  const token = window.localStorage.getItem(name);
  return token;
};

export const saveStorage = (name, val) => {
  window.localStorage.setItem(name, val);
};

export const deleteStorage = (name) => {
  window.localStorage.removeItem(name);
};

export const getSessionStorage = (name) => {
  return window.sessionStorage.getItem(name);
  // try {
  //   const token = window.ReactNativeWebView?.sessionStorage.getItem(name);

  //   return token;
  // } catch {
  //   return;
  // }
};

export const setSessionStorage = (name, val) => {
  return window.sessionStorage.setItem(name, val);
};
