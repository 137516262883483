import { defineStore } from "pinia";

import { postMessage, Device } from "~/src/utils/";

export const usePostMessageStore = defineStore({
  id: "post-message-store",
  state: () => {
    return {
      postMsg: {
        loading: false,
        data: [],
        error: null,
      },
      jwtToken: {
        loading: false,
        data: [],
        error: null,
      },
      hydrate_store: {
        loading: false,
        data: {},
        error: null,
      },
      stepCountInfo: {
        isAuthorized: false,
        isRequestSuccess: false,
        value: 0,
      },
      deviceOS: "android",
      deviceID: "",

      healthData: {
        loading: false,
        isAuthorized: false,
        isRequestSuccess: false,
        todayStep: 0,
      },

      tokenLog: [],
    };
  },
  actions: {
    updatePostLog(data) {
      this.postMsg.data.push(JSON.stringify(data));

      if (data.type == "HYDRATE_STORE") {
        // alert('detected HYDRATE_STORE' + keys + JSON.stringify(data))
        this.updatejwtToken(data.params);
      }
    },
    updatejwtToken(data) {
      // alert('updatejwtToken' + JSON.stringify(data))
      if (data.balanceApp.authorizationDetails.token)
        this.jwtToken.data.push(data.balanceApp.authorizationDetails.token);
      if (data) {
        this.hydrate_store = data;
      }
    },

    pushTokenLog(data) {
      this.tokenLog.push(data);
    },

    // check permission
    // should handle ios/android diff. case here

    // if ios
    // 1. check permission , if no =>false
    // 2. permission true =>try fetch today data
    // if value is 0 ===> no permission /  value today is Zero

    // if android
    // use permission check is ok

    async checkHealthPermission() {
      try {
        //check by postmessage CHECK_PERMISSIONS
        // alert("checkHealthPermission"); // For testing
        const result = await postMessage({
          type: "CHECK_PERMISSIONS",
          params: {
            source: Device.isIos ? "ios" : "android",
          },
        });

        // store if isAuthorized
        if (
          result &&
          result.data &&
          typeof result.data.isAuthorized != "undefined"
        ) {
          // alert("checkHealthPermission result true"); // For testing
          this.healthData.isAuthorized = result.data.isAuthorized;
        } else {
          // alert("checkHealthPermission result false"); // For testing
          this.healthData.isAuthorized = false;
        }

        // alert("checkHealthPermission result: " + JSON.stringify(result)); // For testing
        console.log("check health permission result: ", result);
      } catch (error) {
        // alert("checkHealthPermission error: " + error); // For testing
        console.error("check health permission result error: ", error);
      }
    },

    async fetchTodayStepData() {
      try {
        // alert("fetchTodayStepData: " + JSON.stringify(this.healthData)); // For testing
        // check if it is isAuthorized
        if (!this.healthData.isAuthorized) {
          throw "no permission to get stepData";
        }

        // get by postmessage FETCH_STEP_DATA_BY_DATE_RANGE
        const result = await postMessage({
          type: "FETCH_STEP_DATA_BY_DATE_RANGE",
          params: {
            source: Device.isIos ? "ios" : "android",
            dateRange: {
              startDate: new Date(
                new Date().setHours(0, 0, 0, 0)
              ).toISOString(),
              endDate: new Date().toISOString(),
            },
          },
        });

        // alert("fetchTodayStepData result: " + JSON.stringify(result)); // For testing

        if (
          result &&
          result.data &&
          Array.isArray(result.data) &&
          result.data.length > 0
        ) {
          this.healthData.todayStep = result.data[0].quantity;

          // alert("fetchTodayStepData result (inside if): " + JSON.stringify(result)); // For testing
          console.log("fetchTodayStepData result: ", result);
        } else {
          throw { error: "data format error", data: result };
        }
      } catch (error) {
        console.error("fetchTodayStepData error: ", error);
      }
    },

    async handleFetchTodayStepData() {
      try {
        await this.checkHealthPermission();
        await this.fetchTodayStepData();
      } catch (error) {
        console.error("handleFetchTodayStepData error: ", error);
      }
    },
  },
});
