const APP_API_DOMAIN = import.meta.env.VITE_APP_API_DOMAIN;
export default {
  // login
  login: `${APP_API_DOMAIN}/v1/authentication/login`,
  // profile
  getProfile: `${APP_API_DOMAIN}/v1/users/profile`,

  // articles
  getArticles: `${APP_API_DOMAIN}/v1/articles`,
  getAllNews: `${APP_API_DOMAIN}/v1/articles?type=News&sortColumn=publishedAt&sortDirection=desc`,
  getAllWellness: `${APP_API_DOMAIN}/v1/articles?type=Wellness&sortColumn=publishedAt&sortDirection=desc&limit=100&offset=0`,
  getAllBalanceNews: `${APP_API_DOMAIN}/v1/articles?type=&sortColumn=publishedAt&sortDirection=desc`,

  getArticleById: `${APP_API_DOMAIN}/v1/articles/`,
  getBookmarkArticles: `${APP_API_DOMAIN}/v1/articles/my-bookmarks`,

  likeArticleById: (id) => `${APP_API_DOMAIN}/v1/articles/${id}/like`,
  bookmarkArticleById: (id) => `${APP_API_DOMAIN}/v1/articles/${id}/bookmark`,

  // challenge
  getAvailableChallenges: `${APP_API_DOMAIN}/v1/challenges?viewerTypes=Balance`,
  getProgressChallenges: `${APP_API_DOMAIN}/v1/challenges/my-challenges?isCompleted=false&sortColumn=updatedAt&sortDirection=desc&limit=100&offset=0`,
  getCompletedChallenges: `${APP_API_DOMAIN}/v1/challenges/my-challenges?isCompleted=true`,
  getChallengeById: `${APP_API_DOMAIN}/v1/challenges/`,

  getChallengeProgressById: `${APP_API_DOMAIN}/v1/challenge-progress/`,
  updateChallengeProgress: `${APP_API_DOMAIN}/v1/challenge-progress/`, // use put

  // For test
  getAllWellnessDebug: `${APP_API_DOMAIN}/v1/articles?type=Wellness&sortColumn=publishedAt&sortDirection=desc&limit=100&offset=0&debug`,
};
