import axios from "axios";
import {
  getSessionStorage,
  configHTMLClass,
  setSessionStorage,
} from "~/src/utils/";
import { useCommonStore } from "~/src/stores/common";
import { usePostMessageStore } from "~/src/stores/app";
import { postMessage } from "~/src/utils/";
// import { useBeHomeStore } from "~/src/stores/be/home";

export const appTokenName = "jwtToken";

const axiosApp = axios.create({
  retry: 3,
  retryDelay: 1000,
});

function handleErrorTokenPopup(errorMsg) {
  const commonStore = useCommonStore();
  commonStore.showErrorTokenPopup = true;
  commonStore.errorMsg = errorMsg;
}

export const refreshTokenFn = async (from) => {
  const tokenLog = usePostMessageStore();
  tokenLog.pushTokenLog({ action: "app refreshTokenFn start", data: { from } });

  try {
    const data = await postMessage({ type: "HTTP_REQUEST" });
    // console.log("this is testing data: ", data);
    // alert("testing data in the function refreshTokenFn: " + JSON.stringify(data));
    setSessionStorage(appTokenName, data.token); // update token to sessionStorage
    // alert("saved token to sessionStorage: " + JSON.stringify(data.token));
    // console.log("saved token to sessionStorage: " + JSON.stringify(data.token));

    tokenLog.pushTokenLog({
      action: "app refreshTokenFn success",
      data: { from },
    });

    return data.token;
  } catch (error) {
    // alert(error);
    tokenLog.pushTokenLog({
      action: "app refreshTokenFn error",
      data: { from },
    });

    console.log("refresh token (function) error: ", error);
  }
};

axiosApp.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    const TOKEN = getSessionStorage(appTokenName);
    if (TOKEN) {
      config.headers["Authorization"] = `Bearer ${TOKEN}`;
    }

    // add freeze class to html & display loading screen
    const commonStore = useCommonStore();
    configHTMLClass({ method: "add" });
    commonStore.isLoading = true;

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// app axios responsive
axiosApp.interceptors.response.use(
  async (response) => {
    const commonStore = useCommonStore();

    setTimeout(function () {
      commonStore.isLoading = false;
    }, 1500);

    configHTMLClass({ method: "remove" });

    if (!response.data || response.data.code !== 200) {
      const errorInfo = {
        url: response.config.url,
        request: response.config.data,
        response: response.data,
      };

      commonStore.showErrorPopup = true;
      commonStore.errorMsg = errorInfo;

      throw new Error("data false though error", errorInfo);
    }

    return response;
  },
  async (error) => {
    const tokenLog = usePostMessageStore();

    // alert("error.response.status (app): " + error.response.status);
    const config = error?.config;
    config.__retryCount = config.__retryCount || 0;

    // alert("error: " + JSON.stringify(error));
    // console.log("error: " + JSON.stringify(error));

    if (error?.response?.status === 401 && config.__retryCount < config.retry) {
      // alert("error response status 401: " + JSON.stringify(error.response.status));
      console.log(
        "error response status 401: " + JSON.stringify(error.response.status)
      );

      tokenLog.pushTokenLog({
        action: "app api 401",
        data: { url: config.url },
      });
      try {
        tokenLog.pushTokenLog({
          action: "app api refresh token start: ",
          data: { url: config.url },
        });

        const TOKEN = await refreshTokenFn("from app api");

        tokenLog.pushTokenLog({
          action: "app api refresh success: ",
          data: { url: config.url },
        });

        // alert("Token after run the refreshTokenFn: " + JSON.stringify(TOKEN));
        console.log(
          "Token after run the refreshTokenFn: " + JSON.stringify(TOKEN)
        );

        if (TOKEN) {
          // alert("Test if have Token");
          console.log("Test if have Token");
          config.headers["Authorization"] = `Bearer ${TOKEN}`;
        }

        // alert("recall axios with new token");
        console.log("recall axios with new token");

        config.__retryCount += 1;

        tokenLog.pushTokenLog({
          action: "app api resume: ",
          data: { url: config.url },
        });

        return axiosApp.request(config);
        // return axios(config);
      } catch (err) {
        tokenLog.pushTokenLog({
          action: "app api token refresh fail: ",
          data: { url: config.url },
        });

        console.log(err);
        return Promise.reject(err);
      }
    } else {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        handleErrorTokenPopup(error);
      } else {
        const commonStore = useCommonStore();

        commonStore.showErrorPopup = true;
        commonStore.errorMsg = error;
        commonStore.isLoading = false;
      }
      return Promise.reject(error);
    }
  }
);

export default axiosApp;
