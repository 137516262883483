import { defineStore } from "pinia";
import beAxios from "~/src/network/be/beAxios";
import beEndpoints from "~/src/network/be/beEndpoints";
import { getSessionStorage } from "~/src/utils/";

import { appTokenName } from '~/src/network/app/appAxios';

export const useBeAuthStore = defineStore({
    id: "be-auth-store",
    state: () => ({
        logined: false,
        loginData: {},
        saveLoginRecordToBE: {
            success: false,
            data: {},
        }
    }),
    actions: {
        async beLogin() {
            try {
                let SSOToken = "";

                SSOToken = getSessionStorage(appTokenName);

                //if have token call be login
                const { data } = await beAxios.post(beEndpoints.login, { SSOToken });
                this.loginData = { data };

                //if login success save login success log to BE
                this.saveLoginRecord()

                //handle success flag 
                this.logined = true;
            } catch (error) {
                this.logined = false;
                console.log(error);
            }
        },
        async saveLoginRecord() {
            try {
                this.saveLoginRecordToBE = false;

                const { data } = await beAxios.post(beEndpoints.saveLoginRecord);
                this.saveLoginRecordToBE.data = { data };

                this.saveLoginRecordToBE = true;
            } catch (error) {
                this.saveLoginRecordToBE = false;
                console.log(error);
            }
        },
    },
});
